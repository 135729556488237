import React from 'react';
import { Link } from 'react-router-dom';
import calisan from "../photos/calisan2.png";
import "../pages/Hakkimizda.css";  // Ensure to import your CSS file
import { CiCircleCheck } from "react-icons/ci";

const AboutUsAdvanceEng = () => {
  return (
    <div className='section w-full min-h-screen bg-[#ddd] flex flex-col'>
      <h1 className='elcustom'>
        AboutUs
      </h1>
      <section className='element-section '>
        <div className='element-container'>
          <div className='element-column'>
            <div className='element-widget-wrap'>
              <div className='element-widget-container'>
                <div className='about-container'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='image-colarge'>
                        <div className='colarge-1'>
                        <img src={calisan} alt='worker' className='slide-righ'/>
                        </div>
                        <div className='since-year-outer'>
                          <div className='since-year'>
                          <strong>Since</strong>
                            <span >2011</span>
                          
                          </div>
                        </div>
                      </div>                      
                    </div>
                    <div className='col-lg-6 col-md-12' >
                      <div className='welcome-to-section'>
                        <div className='left wt-small-separator-outer '>
                          <div className='wt-small-separator site-text-primary'>
                            <div className='sep-leaf-left'>
                              <div className='text-[#1c63b8] text-xl font-bold font-mono'>
                              Engineering Services and Service Solutions</div>
                            </div>
                          </div>
                        </div>

                        <h2 className='bh2'>
                        Experienced <span>Expert Staff  with</span> Over 10 Years of Experience</h2>
                        <p className='bp'> COMPRIMAS was established in 2011 to provide professional solutions for industrial spare parts manufacturing and special pipe production needs. Specializing in the production of parts for cranes, compressors, and similar tools, our company expanded its product range in 2017 in response to customer satisfaction and demands, offering more comprehensive services. Within this framework, COMPRIMAS has achieved a significant position in the industry with its customer-focused approach and commitment to quality service. With innovative production techniques and the ability to adapt to continuously evolving technology, COMPRIMAS maintains its leadership position in the sector. Our mission is to optimize our customers' business processes by providing them with the highest quality products and services.</p>
                        <ul className='checklist '>
                          <li className='flex flex-row gap-2 mt-2 '><CiCircleCheck   className='text-blue-700 text-xl '/>Expert Solutions</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Business Management Service</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Expert Team in Machine Maintenance</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' /> 24/7 Intervention Capability</li>
                        </ul>
                        <ul className='checklist2'>
                          <li className='flex flex-row  gap-2'><CiCircleCheck className='text-blue-700 text-xl' />Consulting Service</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Service Availability Across All of Turkey</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Over 10 Years of Experience</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Numerous Factory and Power Plant References</li>                      
                        </ul>
                        <div className='welcom-to-section-bottom'></div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
     
    </div>
  );
}

export default AboutUsAdvanceEng;
