import React from 'react';
import "./AboutUs.css";
import img1 from "../photos/img6.png";
import img2 from "../photos/img2.jpg";
import img3 from "../photos/img3.jpg";
import img4 from "../photos/img5.jpg";
import { FaGear } from "react-icons/fa6";
import { PiPipeWrenchFill } from "react-icons/pi";
import { FaShip } from "react-icons/fa";
import { FaCircleDot } from "react-icons/fa6";
import { motion } from 'framer-motion';

const AboutUs = () => {
  return (
    <div className='mt-[20vh] text-center'>
      <h1 className="custom-font">"From production until arrival, we will be with you."</h1> 
      <section className='mt-[8vh]'>
        <div className=' flex justify-center ' id='container'>
            <motion.div className='w-fulli mini-container relative ml-8'
             initial="hidden"
             whileInView="visible"
             viewport={{once:true, amount:0.5}}
             transition={{duration:1}}
             variants={{
               hidden:{opacity:0, y:-200},
               visible:{opacity:1 , y:0}
             }} 
            >
              <div className="gear-wrapper">
                <FaGear className="gear-icon" />
              </div>
              <img src={img1} alt='yedek-parça' className="small-image"/> 
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>Cnc Operations</h1>
              <h2 className='mt-4 mx-4'> It offers customized solutions for industrial needs, producing high-quality parts for continuous production and efficiency.</h2>
              </div>
            </motion.div>
          
            <motion.div className='w-fulli mini-container relative'
            initial="hidden"
            whileInView="visible"
            viewport={{once:true, amount:0.5}}
            transition={{duration:1}}
            variants={{
              hidden:{opacity:0, y:-100},
              visible:{opacity:1 , y:0}
            }}   
            >
              <div className="gear-wrapper">
                <PiPipeWrenchFill  className="gear-icon" />
              </div>
              <img src={img2} alt='boru' className="small-image"/>
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>Pipe Manufacture</h1>
              <h2 className='mt-4 mx-4 text-center'>We are a leader in pipe production! We meet our customers' expectations by producing high-quality and durable pipes for industrial needs.</h2>
              </div>
            </motion.div>
            <motion.div className='w-fulli mini-container relative'        
            initial="hidden"
            whileInView="visible"
            viewport={{once:true, amount:0.5}}
            transition={{duration:1}}
            variants={{
              hidden:{opacity:0, x:200},
              visible:{opacity:1 , x:0}
            }}   >
              <div className="gear-wrapperi">
                <FaShip className="gear-icon" />
              </div>
              <img src={img3} alt='taşımacılık' className="small-image"/>
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>Export Import</h1>
              <h2 className='mt-4 mx-4'>By operating in global markets, we provide products and services of international quality standards to our customers in various sectors.</h2>
              </div>
            </motion.div>
            <motion.div className='w-fulli mini-container relative'
              initial="hidden"
              whileInView="visible"
              viewport={{once:true, amount:0.5}}
              transition={{duration:1}}
              variants={{
                hidden:{opacity:0, x:100},
                visible:{opacity:1 , x:0}
              }}   
            >
              <div className="gear-wrapper  ">
                <FaCircleDot   className="gear-icon" />
              </div>
              <img src={img4} alt='üretim' className="small-image"/>
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>Bearing Production</h1>
              <h2 className='mt-4 mx-4'>We are experts in bearing production! We manufacture high-quality and high-performance bearings specifically designed for your industrial needs.</h2>
              </div>
            </motion.div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;