import React from 'react'
import HeaderEng from "./components/HeaderEng"
import AboutUsEng from './components/AboutUsEng'
import Slider from './components/Slider'
import FooterEng from './components/FooterEng'
const EngApp = () => {
  return (
    <div>
    <HeaderEng></HeaderEng>
    <Slider></Slider>
    <AboutUsEng></AboutUsEng>
    <FooterEng></FooterEng>
    </div>
  )
}

export default EngApp
