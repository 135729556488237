import React from 'react'
import HeaderEng from '../components/HeaderEng'
import Slider from '../components/Slider'
import "./Hizmetlerimiz.css"
import ServicesEng from '../components/ServicesEng'
import FooterEng from "../components/Footer"

const HizmetlerimizEng = () => {
  return (
    <div className='flex flex-col hizmet '>
     <HeaderEng></HeaderEng>
     <Slider></Slider>
     <h1 className='custom-font2 '>OurServices</h1>
     <ServicesEng></ServicesEng>
     <FooterEng></FooterEng>
    </div>
  )
}

export default HizmetlerimizEng