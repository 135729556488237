import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import HeaderEng from '../components/HeaderEng';
import "./Contact.css"

const ContactEng = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Navigate to the home page
    navigate('/eng');
  };

  return (
    <div className='flex flex-col'>
      <HeaderEng />
      <div className='mt-[8rem] text-center '>
        <h1 className='text-5xl font-bold mb-6'>Contact Form</h1>
        <form onSubmit={handleSubmit}> {/* Add onSubmit event handler */}
          <input type='text' name='first' placeholder='FirstName' required className='input-field'/>
          <input type='text' name='last' placeholder='LastName' required className='input-field'/>
          <input type='text' name='email' placeholder='Email Adress' required className='input-field'/>
          <textarea rows="5" cols="60" name='message' placeholder='Your message' className='input-field'></textarea>
          <button type='submit' className='submit-button'>SEND</button>
        </form>
      </div>
    </div>
  );
};

export default ContactEng;