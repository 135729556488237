import React from 'react'
import Header from '../components/Header'
import Slider from '../components/Slider'
import "./Export.css"
import Exporto from "../photos/Export.jpg"
import Footer from '../components/Footer'
import { motion } from 'framer-motion'

const Export = () => {
  return (
    <div>
      <Header></Header>
      <Slider></Slider>
      <h1 className='custom-font3 '> İthalat İhracat</h1>
      <div className="grid grid-cols-4 ml-5 mx-5 gap-4 helper sm-max:grid sm-max:grid-cols-1">
  <motion.div className="col-span-1"
   initial="hidden"
   whileInView="visible"
   viewport={{once:true, amount:0.5}}
   transition={{duration:1}}
   variants={{
     hidden:{opacity:0, x:100},
     visible:{opacity:1 , x:0}
   }}   
  >
    <img alt="export" src={Exporto} className="h-full w-full object-cover"></img>
  </motion.div>
  <motion.div className="col-span-3 border border-gray-300 shadow-md p-4"
   initial="hidden"
   whileInView="visible"
   viewport={{once:true, amount:0.5}}
   transition={{duration:1}}
   variants={{
     hidden:{opacity:0, x:-100},
     visible:{opacity:1 , x:0}
   }}   
  >
    <h2 className="text-2xl font-bold mb-2">İTHALAT - İHRACAAT</h2>
    <p className="text-lg">Müşterilerimizle güçlü ve sürdürülebilir ortaklık kurulması, onların değişen taleplerine cevap vermek adına ürün kategorilerinin sürekli olarak gözden geçirilip, ürün yelpazemizi çeşitlendirip ve gerekli yatırımları yapmaktayız.</p>
</motion.div>

</div>
<Footer></Footer>
    </div>
  )
}

export default Export
