// let list=document.querySelector(".slider .list");
// let items=document.querySelectorAll(".slider .list .item");
// let prev=document.getElementById("prev");
// let next=document.getElementById("next");
// let active=0;

// next.onclick=function(){
//     reloadSlider();
// }
// function reloadSlider(){
//     let checkLeft=items[active].offsetLeft;
//     list.style.left = -checkLeft + "px";
// }




// import { useState, useEffect, useRef } from 'react';

// const useSlider = (sliderRef, itemClass) => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const listRef = useRef(null);
//   const itemsRef = useRef([]);

//   useEffect(() => {
//     if (sliderRef.current) {
//       listRef.current = sliderRef.current.querySelector('.list');
//       itemsRef.current = sliderRef.current.querySelectorAll(itemClass);
//       reloadSlider();
//     }
//   }, [sliderRef, activeIndex]);

//   const reloadSlider = () => {
//     if (itemsRef.current.length > 0) {
//       const checkLeft = itemsRef.current[activeIndex].offsetLeft;
//       if (listRef.current) {
//         listRef.current.style.left = -checkLeft + 'px';
//       }
//     }
//   };

//   const next = () => {
//     if (activeIndex < itemsRef.current.length - 1) {
//       setActiveIndex((prevIndex) => prevIndex + 1);
//     }
//   };

//   const prev = () => {
//     if (activeIndex > 0) {
//       setActiveIndex((prevIndex) => prevIndex - 1);
//     }
//   };

//   return { next, prev };
// };

// export default useSlider;


import { useState, useEffect, useRef } from 'react';

const useSlider = (sliderRef, itemClass, autoSlideInterval = 5000) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const listRef = useRef(null);
  const itemsRef = useRef([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      listRef.current = sliderRef.current.querySelector('.list');
      itemsRef.current = sliderRef.current.querySelectorAll(itemClass);
      reloadSlider();
      startAutoSlide();
    }
    
    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [sliderRef, activeIndex]);

  const reloadSlider = () => {
    if (itemsRef.current.length > 0) {
      const checkLeft = itemsRef.current[activeIndex].offsetLeft;
      if (listRef.current) {
        listRef.current.style.left = -checkLeft + 'px';
      }
    }
  };

  const next = () => {
    if (activeIndex < itemsRef.current.length - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      setActiveIndex(0); // Reset to the first slide
    }
  };

  const prev = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    } else {
      setActiveIndex(itemsRef.current.length - 1); // Go to the last slide
    }
  };

  const startAutoSlide = () => {
    clearInterval(intervalRef.current); // Clear any existing interval
    intervalRef.current = setInterval(next, autoSlideInterval); // Start new interval
  };

  return { next, prev, startAutoSlide };
};

export default useSlider;

