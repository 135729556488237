import React from 'react';
import { Link } from 'react-router-dom';

const FooterEng = () => {
  return (
    <div className=''>
      <footer className="  bg-gray-800 text-white py-8 mt-5 flex flex-col">
        <div className=" mx-auto grid grid-cols-2 gap-[15rem] sm-max:gap-4 flex-grow p-11 mr-10">
          {/* First Column */}
          <div className='p-2'>
            <h3 className="text-lg font-semibold">About Us</h3>
            <p>High quality, reliability, and an innovative approach are our priorities.</p>
            <div className='mt-10'>
            <p>E-mail: </p>  
            <a href='mailto:operation@comprimas.com' style={{fontSize:16}}>operation@comprimas.com</a>
            </div>
          </div>
          {/* Second Column */}
          {/* <div className='p-2'>
            <h3 className="text-lg font-semibold">Fast Links</h3>
            <ul>
              <li><a href="#" className="text-gray-300 hover:text-white">Home Page</a></li>
              <li><Link href="/hizmetlerimiz" className="text-gray-300 hover:text-white">Our Services</Link></li>
              <li><a href="#" className="text-gray-300 hover:text-white">Detailed Contact</a></li>
            </ul>
          </div> */}
          {/* Third Column */}
          <div className='p-2 flex flex-col'> {/* Ensuring content wraps within the available space */}
            <h3 className="text-lg font-semibold">Location </h3>
            <p>Sultan Selim Mah. Sultan Selim Cad. Side İş Merkezi Floor:5 N:5</p>  
            <br></br>   
            <p> Kağıtname İstanbul/Türkiye</p>  
            
          </div>
        </div>
      </footer>
      <div className='footer__bottom w-full text-white bg-[#6c6c6c]'>@  2024  comprimas </div>
    </div>
  );
};

export default FooterEng;