import React from 'react'
import Header from '../components/Header'
import AboutUsAdvance from '../components/AboutUsAdvance'
import "../pages/Hakkimizda.css"
import Slider from "../components/Slider"
import Footer from "../components/Footer"

const Hakkimizda = () => {
  return (
    <div className='bg-[#ddd] z-0 '>
    <div className='mt-[10rem]'>
     <Header></Header> 
     <AboutUsAdvance ></AboutUsAdvance>
     <div className='mt-[-100px]'>
     <Slider></Slider>
     </div>
     <div className=' mt-[10rem]' ></div>
     <Footer></Footer>
     
    </div>
    </div>
  )
}

export default Hakkimizda
