import './App.css';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Header from './components/Header';
import Slider from './components/Slider';
import { Router, Routes ,Route} from 'react-router';
import Eng from './EngApp';
import Hizmetlerimiz from './pages/Hizmetlerimiz';
import Contact from './pages/Contact';
import ContactEng from './pages/ContactEng';
import Export from './pages/Export';
import HizmetlerimizEng from './pages/HizmetlerimizEng';
import ExportEng from './pages/ExportEng';
import Hakkimizda from './pages/Hakkimizda';
import HakkimizdaEng from './pages/HakkimizdaEng';


function App() {
  return (
  
    <div className="App flex flex-col mx-auto ">
  
    <Routes>
          <Route  path="/" element={<>  <Header></Header><Slider /><AboutUs /> <Footer></Footer> </>} />
          {/* <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} /> */}
          <Route path="/eng" element={<Eng />} /> {/* Add the new Eng route */}
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path='/eng/contact' element={<ContactEng></ContactEng>} ></Route>
          <Route path='/hizmetlerimiz' element={<Hizmetlerimiz></Hizmetlerimiz>}></Route>
          <Route path='/export' element={<Export></Export>}></Route>
          <Route path='/eng/hizmetlerimiz' element={<HizmetlerimizEng></HizmetlerimizEng>}></Route>
          <Route path='/export' element={<Export></Export>}></Route>
          <Route path='/eng/export' element={<ExportEng></ExportEng>}></Route>
          <Route path='/hakkimizda' element={<Hakkimizda></Hakkimizda>}></Route>
          <Route path='/eng/hakkimizda' element={<HakkimizdaEng></HakkimizdaEng>}></Route>
        </Routes>
    
    </div>
   
  );
}

export default App;
