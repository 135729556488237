import React from 'react'
import HeaderEng from '../components/HeaderEng'
import Slider from '../components/Slider'
import "./Export.css"
import Exporto from "../photos/Export.jpg"
import FooterEng from '../components/FooterEng'
import { motion } from 'framer-motion'
const ExportEng = () => {
  return (
    <div>
      <HeaderEng></HeaderEng>
      <Slider></Slider>
      <h1 className='custom-font3 '> Export Import</h1>
      <div className="grid grid-cols-4 ml-5 mx-5 gap-4 helper sm-max:grid sm-max:grid-cols-1">
  <motion.div className="col-span-1"
   initial="hidden"
   whileInView="visible"
   viewport={{once:true, amount:0.5}}
   transition={{duration:1}}
   variants={{
     hidden:{opacity:0, x:100},
     visible:{opacity:1 , x:0}
   }}   
  >
    <img alt="export" src={Exporto} className="h-full w-full object-cover"></img>
  </motion.div>
  <motion.div className="col-span-3 border border-gray-300 shadow-md p-4"
   initial="hidden"
   whileInView="visible"
   viewport={{once:true, amount:0.5}}
   transition={{duration:1}}
   variants={{
     hidden:{opacity:0, x:-100},
     visible:{opacity:1 , x:0}
   }}   
  >
    <h2 className="text-2xl font-bold mb-2">Export Import</h2>
    <p className="text-lg">We are constantly reviewing our product categories, diversifying our product range, and making necessary investments to respond to the changing demands of our customers and establish strong and sustainable partnerships with them.</p>
</motion.div>

</div>
<FooterEng></FooterEng>
    </div>
  )
}

export default ExportEng;
