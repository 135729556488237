import React from 'react';
import "./AboutUs.css";
import img1 from "../photos/img6.png";
import img2 from "../photos/img2.jpg";
import img3 from "../photos/img3.jpg";
import img4 from "../photos/img5.jpg";
import { FaGear } from "react-icons/fa6";
import { PiPipeWrenchFill } from "react-icons/pi";
import { FaShip } from "react-icons/fa";
import { FaCircleDot } from "react-icons/fa6";
import { motion } from 'framer-motion';

const AboutUs = () => {
  return (
    <div className='mt-[20vh] text-center'>
      <h1 className="custom-font">"Projenizin Başından Üretimin Sonuna Kadar Sizlerle Birlikteyiz"</h1> 
      <section className='mt-[8vh]'>
        <div className=' flex justify-center ' id='container'>
            <motion.div className='w-fulli mini-container relative ml-8'
             initial="hidden"
             whileInView="visible"
             viewport={{once:true, amount:0.5}}
             transition={{duration:1}}
             variants={{
               hidden:{opacity:0, y:-100},
               visible:{opacity:1 , y:0}
             }}   
            >
              <div className="gear-wrapper">
                <FaGear className="gear-icon" />
              </div>
              <img src={img1} alt='yedek-parça' className="small-image"/> 
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>CNC işlemleri</h1>
              <h2 className='mt-4 mx-4'> Endüstriyel ihtiyaçlara özelleştirilmiş çözümler sunar, üretim sürekliliği ve verimliliği için kaliteli parçalar üretir</h2>
              </div>
            </motion.div>
          
            <motion.div className='w-fulli mini-container relative'
             initial="hidden"
             whileInView="visible"
             viewport={{once:true, amount:0.5}}
             transition={{duration:1}}
             variants={{
               hidden:{opacity:0, y:-100},
               visible:{opacity:1 , y:0}
             }}   
            >
              <div className="gear-wrapper">
                <PiPipeWrenchFill  className="gear-icon" />
              </div>
              <img src={img2} alt='boru' className="small-image"/>
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>Boru üretimi</h1>
              <h2 className='mt-4 mx-4 text-center'>Boru üretiminde lideriz! Endüstriyel ihtiyaçlara yönelik kaliteli ve dayanıklı borular üreterek müşterilerimizin beklentilerini karşılıyoruz.</h2>
              </div>
            </motion.div>
            <motion.div className='w-fulli mini-container relative'
             initial="hidden"
             whileInView="visible"
             viewport={{once:true, amount:0.5}}
             transition={{duration:1}}
             variants={{
               hidden:{opacity:0, x:200},
               visible:{opacity:1 , x:0}
             }}   
            >
              <div className="gear-wrapperi">
                <FaShip className="gear-icon" />
              </div>
              <img src={img3} alt='taşımacılık' className="small-image"/>
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>İthalat İhracat</h1>
              <h2 className='mt-4 mx-4'>İthalat ve ihracat; küresel pazarlarda faaliyet göstererek, çeşitli sektörlerdeki müşterilerimize uluslararası kalite standartlarında ürün ve hizmetleri sunarız.</h2>
              </div>
            </motion.div>
            <motion.div className='w-fulli mini-container relative'
             initial="hidden"
             whileInView="visible"
             viewport={{once:true, amount:0.5}}
             transition={{duration:1}}
             variants={{
               hidden:{opacity:0, x:100},
               visible:{opacity:1 , x:0}
             }}   
            >
              <div className="gear-wrapper  ">
                <FaCircleDot   className="gear-icon" />
              </div>
              <img src={img4} alt='üretim' className="small-image"/>
              <div className='text-center'>
              <h1 className='mt-12 font-bold'>Rulman Üretimi</h1>
              <h2 className='mt-4 mx-4'>Rulman üretiminde uzmanız! Endüstriyel ihtiyaçlarınız için özel tasarlanmış yüksek kaliteli ve performanslı rulmanlar üretiyoruz.</h2>
              </div>
            </motion.div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;