import React from 'react'
import { VscProject } from "react-icons/vsc";
import { MdOutlineConstruction } from "react-icons/md";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TbSettingsUp } from "react-icons/tb";
import { motion } from 'framer-motion';

const ServicesEng = () => {
  return (
    <div className='services-section '>
      <motion.div className='card'
       initial="hidden"
       whileInView="visible"
       viewport={{once:true, amount:0.5}}
       transition={{duration:1}}
       variants={{
         hidden:{opacity:0, x:-100},
         visible:{opacity:1 , x:0}
       }}   
      >
        <div className='icon'>
        <VscProject />
        </div>
        <h2>Project Consultancy</h2>
        <p>Our company providing project consultancy ensures the success of projects within their time and budget by offering expert support in planning, management, and implementation processes while minimizing risks.</p>
        <a href='/' className='button'>More</a>
      </motion.div>

      <motion.div className='card'
       initial="hidden"
       whileInView="visible"
       viewport={{once:true, amount:0.5}}
       transition={{duration:1}}
       variants={{
         hidden:{opacity:0, x:100},
         visible:{opacity:1 , x:0}
       }}   
      >
        <div className='icon'>
        <MdOutlineConstruction />
        </div>
        <h2>Construction</h2>
        <p>Our company specializes in construction consulting, offering guidance in planning, management, and implementation processes to ensure successful completion of construction projects.</p>
        <a href='/' className='button'>More</a>
      </motion.div>

      <motion.div className='card'
       initial="hidden"
       whileInView="visible"
       viewport={{once:true, amount:0.5}}
       transition={{duration:1}}
       variants={{
         hidden:{opacity:0, x:-100},
         visible:{opacity:1 , x:0}
       }}   
      >
        <div className='icon'>
        <LiaChalkboardTeacherSolid />
        </div>
        <h2>Feasibility and Study</h2>
        <p>Feasibility and study assessments meticulously evaluate project feasibility, conduct cost analyses, identify risks, and propose innovative solutions, ensuring successful project initiations. We differentiate with our quality.</p>
        <a href='/' className='button'>More</a>
      </motion.div>

      <motion.div className='card'
       initial="hidden"
       whileInView="visible"
       viewport={{once:true, amount:0.5}}
       transition={{duration:1}}
       variants={{
         hidden:{opacity:0, x:100},
         visible:{opacity:1 , x:0}
       }}   
      >
        <div className='icon'>
        <TbSettingsUp />
        </div>
        <h2>Installation</h2>
        <p>In the installation process, we proceed with care at every step. We offer detailed solutions, considering specific needs during software installation. We work diligently for customer satisfaction and seamless setup.</p>
        <a href='/' className='button'>More</a>
      </motion.div>
    </div>
  )
}

export default ServicesEng
