import { useEffect } from 'react';

const useToggleMenu = (toggleId, navId) => {
    useEffect(() => {
        const toggle = document.getElementById(toggleId);
        const nav = document.getElementById(navId);

        const handleClick = () => {
            nav.classList.toggle('show-menu');
            toggle.classList.toggle('show-icon');
        };

        if (toggle) {
            toggle.addEventListener('click', handleClick);
        }

        return () => {
            if (toggle) {
                toggle.removeEventListener('click', handleClick);
            }
        };
    }, [toggleId, navId]);
};

export default useToggleMenu;