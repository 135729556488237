import React from 'react'
import HeaderEng from '../components/HeaderEng'
import AboutUsAdvanceEng from '../components/AboutUsAdvanceEng'
import Slider from '../components/Slider'
import FooterEng from "../components/FooterEng"

const HakkimizdaEng = () => {
  return (
    <div className='bg-[#ddd] z-0 '>
    <div className='mt-[10rem]'>
     <HeaderEng></HeaderEng>
     <AboutUsAdvanceEng></AboutUsAdvanceEng>
     <div className='mt-[-100px]'>
     <Slider></Slider>
     </div>
     <div className=' mt-[10rem]' ></div>
     <FooterEng></FooterEng>

    </div>
    </div>
  )
}

export default HakkimizdaEng
