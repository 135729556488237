// import React from 'react'
// import slider1 from "../photos/slider1.jpg"
// import slider2 from "../photos/slider7.jpg"
// import slider4 from "../photos/slider4.png"
// import slider5 from "../photos/slider5.png"
// import slider6 from "../photos/slider6.png"
// import "../components/Slider.css"
// import { FaArrowLeftLong } from "react-icons/fa6";
// import { FaArrowRightLong } from "react-icons/fa6";
// import useSlider from '../hooks/useSlider';

// const Slider = () => {
    
//   return (
//     <div className='slider  mt-[100px] '>
//       <div className='list'>
//         <div className='item'>
//             <img src={slider1} alt='yedek-parça'></img>
//         </div>
//         <div className='item'>
//         <img src={slider2} alt='rulman'></img>
//         </div>
//         <div className='item'>
//         <img src={slider4} alt='ithalat'></img>
//         </div>
//         <div className='item'>
//         <img src={slider5} alt='ihracat'></img>
//         </div>
//         <div className='item'>
//         <img src={slider6} alt='boru'></img>
//         </div>
//         {/* BUTTONS */}
//         <div className='buttons'>
//             <button   id="prev"><FaArrowLeftLong className='ml-3 absolute' />  </button>
//             <button id="next"> <FaArrowRightLong className='mr-5 absolute left-[75vw]' /> </button>
                
               
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Slider




// import React, { useRef } from 'react';
// import slider1 from "../photos/slider1.jpg";
// import slider2 from "../photos/slider7.jpg";
// import slider4 from "../photos/slider4.png";
// import slider5 from "../photos/slider5.png";
// import slider6 from "../photos/slider6.png";
// import "../components/Slider.css";
// import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
// import useSlider from '../hooks/useSlider';

// const Slider = () => {
//   const sliderRef = useRef(null);
//   const { next, prev } = useSlider(sliderRef, '.item');

//   return (
//     <div className='slider ' ref={sliderRef}>
//       <div className='list'>
//         <div className='item'>
//           <img src={slider1} alt='yedek-parça' />
//         </div>
//         <div className='item'>
//           <img src={slider2} alt='rulman' />
//         </div>
//         <div className='item'>
//           <img src={slider4} alt='ithalat' />
//         </div>
//         <div className='item'>
//           <img src={slider5} alt='ihracat' />
//         </div>
//         <div className='item'>
//           <img src={slider6} alt='boru' />
//         </div>
//       </div>
//       {/* BUTTONS */}
//       <div className='buttons'>
//         <button id="prev" onClick={prev}><FaArrowLeftLong /></button>
//         <button id="next" onClick={next}><FaArrowRightLong /></button>
//       </div>
//     </div>
//   );
// }

// export default Slider;

import React, { useRef } from 'react';
import slider1 from "../photos/slider-1.jpg";
import slider2 from "../photos/slider7.jpg";
import slider4 from "../photos/slider4.png";
import slider5 from "../photos/slider5.png";

import "../components/Slider.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import useSlider from '../hooks/useSlider';
import slider7 from "../photos/calisma3.png"
import slider8 from "../photos/makinaortam1.png" 

const Slider = () => {
  const sliderRef = useRef(null);
  const { next, prev } = useSlider(sliderRef, '.item', 5000); // 3000ms = 3 seconds

  return (
    <div className='slider mt-[100px]' ref={sliderRef}>
      <div className='list'>

      <div className='item'>
          <img src={slider8} alt='ortam' />
        </div>
        <div className='item'>
          <img src={slider1} alt='yedek-parça' />
        </div>
        <div className='item'>
          <img src={slider2} alt='rulman' />
        </div>
        <div className='item'>
          <img src={slider4} alt='ithalat' />
        </div>
        <div className='item'>
          <img src={slider5} alt='ihracat' />
        </div>
        {/* <div className='item'>
          <img src={slider6} alt='boru' />
        </div> */}
        <div className='item'>
          <img src={slider7} alt='ortam' />
        </div>
        
      </div>
      {/* BUTTONS */}
      <div className='buttons'>
        <button id="prev" onClick={prev}><FaArrowLeftLong /></button>
        <button id="next" onClick={next}><FaArrowRightLong /></button>
      </div>
    </div>
  );
}

export default Slider;

