import React from 'react'
import Header from '../components/Header'
import Slider from '../components/Slider'
import "./Hizmetlerimiz.css"
import Services from '../components/Services'
import Footer from "../components/Footer"

const Hizmetlerimiz = () => {
  return (
    <div className='flex flex-col hizmet '>
     <Header></Header>
     <Slider></Slider>
     <h1 className='custom-font2 '>Hizmetlerimiz</h1>
     <Services></Services>
     <Footer></Footer>
    </div>
  )
}

export default Hizmetlerimiz
