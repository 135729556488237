import React from 'react'
import logo from "../photos/comprimasLogo.jpg"
import { TiThMenu } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import { AiFillHome } from "react-icons/ai";
import { FaArrowDown } from "react-icons/fa6";
import { FaPersonChalkboard } from "react-icons/fa6";
import { FaUpDown } from "react-icons/fa6";
import "../App.css"
import useToggleMenu from '../hooks/useMenu';
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { RiMoneyPoundBoxLine } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { TbLetterE } from "react-icons/tb";
import { TbLetterN } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { CiBoxList } from "react-icons/ci";
import { FaGripLinesVertical } from "react-icons/fa";
import { TbBrandSuperhuman } from "react-icons/tb";


const Header = () => {
    useToggleMenu('nav-toggle', 'nav-menu');
  return (
  <header className='header fixed top-0 left-0 width:100%  '>
    <nav className='nav container relative'>
      <Link className='lanquage absolute right-10 flex flex-row mt-4 mr-[3rem] bg min-xl:left-[25%] min-xl:right-0' to='/eng'>
      <TbLetterE  className='bg-blue-600'/>
      <TbLetterN  className='bg-red-600'/>
      </Link>
        <div className='nav__data flex flex-row justify-between items-center '>
            <img className='nav__logo' src={logo} alt='logo' style={{width:200,height:90}}/>
    

        <div className='nav__toggle' id='nav-toggle'>
        <TiThMenu  className='nav__burger text-white'   />
        <IoMdClose className='nav__close  text-white ' />
        </div>    
        </div>

         {/* ------------------  NAV MENU------------- */}
        <div className='nav__menu mt-6 ' id='nav-menu'>
            <ul className='nav__list'>

            <li className=' '><Link to="/" class="nav__link " ><AiFillHome /> Ana Sayfa</Link></li>

            <li className='dropdown__item'>
               <div className='nav__link'>
                  <Link to="/hakkimizda" className='flex flex-row'>
               <TbBrandSuperhuman className='mt-1' /> Hakkımızda
               </Link>
               </div>

            </li> 

                {/* <!--=============== DROPDOWN 1 ===============--> */}
                <li class="dropdown__item">
                <div class="nav__link flex flex-row gap-1 ">
                         <FaArrowDown />Hizmetlerimiz
                     </div>
                     <ul class="dropdown__menu">
                     <li>
                     <Link to="/hizmetlerimiz" class="dropdown__link flex flex-row ">
                     <FaPersonChalkboard />Proje Danışmanlık
                           </Link> 
                     </li>
                     <li>
                           {/* <a href="#" class="dropdown__link flex flex-row gap-1">
                          İnşaat
                           </a> */}
                        </li>
                        {/* <!--=============== DROPDOWN SUBMENU ===============--> */}
                        <li class="dropdown__subitem">
                        <div class="dropdown__link">
                        <FaUpDown /> İnşaat <i class="ri-add-line dropdown__add"></i>
                           </div>
                           <ul class="dropdown__submenu">
                              <li>
                                 <Link to="/hizmetlerimiz" class="dropdown__sublink">
                                 <CiBoxList /> Fizibilite ve Etüd
                                 </Link>
                              </li>
      
                              <li>
                                 <Link to="/hizmetlerimiz" class="dropdown__sublink">
                                 <FaGripLinesVertical /> Kurulum
                                 </Link>
                              </li>
{/*       
                              <li>
                                 <a href="#" class="dropdown__sublink">
                                    <i class="ri-refund-2-line"></i> 
                                 </a>
                              </li> */}
                           </ul>
                        </li>

                     </ul>
                </li>
                {/* <li><a href="#" class="nav__link">Ticaret</a></li> */}

                {/* <!--=============== DROPDOWN 2 ===============--> */}
                <li class="dropdown__item">
                     <div class="nav__link">
                    <RiMoneyPoundBoxLine  style={{fontSize:25 , marginRight:3}}/>  Ticaret
                     </div>

                     <ul class="dropdown__menu ">
                        <li >
                           <Link to="/export"  class="dropdown__link ">
                           <MdOutlineShoppingCartCheckout /> İhracat
                           </Link>                          
                        </li>

                        <li>
                           <Link to="/export" class="dropdown__link">
                           <MdOutlineShoppingCartCheckout />  İthalat
                           </Link>
                        </li>
{/* 
                        <li>
                           <a href="#" class="dropdown__link">
                              <i class="ri-message-3-line"></i> Messages
                           </a>
                        </li> */}
                     </ul>
                  </li>

                  <li><Link to="/contact" class="nav__link">  <FaPhoneAlt style={{fontSize:20 , marginRight:3}} />Bize ulaşın</Link></li>
            </ul>
        </div>

    </nav>
  </header>
  )
}

export default Header
