import React from 'react';
import { Link } from 'react-router-dom';
import calisan from "../photos/calisan2.png";
import "../pages/Hakkimizda.css";  // Ensure to import your CSS file
import { CiCircleCheck } from "react-icons/ci";

const AboutUsAdvance = () => {
  return (
    <div className='section w-full min-h-screen bg-[#ddd] flex flex-col'>
      <h1 className='elcustom'>
        Hakkımızda
      </h1>
      <section className='element-section '>
        <div className='element-container'>
          <div className='element-column'>
            <div className='element-widget-wrap'>
              <div className='element-widget-container'>
                <div className='about-container'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='image-colarge'>
                        <div className='colarge-1'>
                        <img src={calisan} alt='worker' className='slide-righ'/>
                        </div>
                        <div className='since-year-outer'>
                          <div className='since-year'>
                            <span >2011</span>
                            <strong>'den Beri</strong>
                          </div>
                        </div>
                      </div>                      
                    </div>
                    <div className='col-lg-6 col-md-12' >
                      <div className='welcome-to-section'>
                        <div className='left wt-small-separator-outer '>
                          <div className='wt-small-separator site-text-primary'>
                            <div className='sep-leaf-left'>
                              <div className='text-[#1c63b8] text-xl font-bold font-mono'>Mühendislik Hizmetleri ve Servis Çözümleri</div>
                            </div>
                          </div>
                        </div>

                        <h2 className='bh2'>Tecrübeli <span>Uzman Kadro</span> 10 Yılı Aşkın Tecrübe</h2>
                        <p className='bp'> COMPRIMAS, 2011 yılında endüstriyel yedek parça imalatı ve özel boru üretimi ihtiyaçlarına profesyonel çözümler sunmak amacıyla kurulmuştur. Vinç, kompresör ve benzeri aletler için parça üretimi konusunda uzmanlaşan firmamız, 2017 yılında müşteri memnuniyeti ve talepleri doğrultusunda ürün yelpazesini genişleterek, daha kapsamlı hizmetler sunmaya başlamıştır. Bu çerçevede, müşteri odaklı yaklaşımı ve kaliteli hizmet anlayışı ile sektörde önemli bir konuma gelmiştir. Yenilikçi üretim teknikleri ve sürekli gelişen teknolojiye uyum sağlama kabiliyeti ile COMPRIMAS, sektördeki lider konumunu korumaktadır. Misyonumuz, müşterilerimize en yüksek kalitede ürünler ve hizmetler sunarak onların iş süreçlerini optimize etmektir.</p>
                        <ul className='checklist '>
                          <li className='flex flex-row gap-2 mt-2 '><CiCircleCheck   className='text-blue-700 text-xl '/>Uzman çözümler</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />İşletme sorumluluğu hizmeti</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Makine bakımında uzman kadro</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' /> 7/24 müdahale imkanı</li>
                        </ul>
                        <ul className='checklist2'>
                          <li className='flex flex-row  gap-2'><CiCircleCheck className='text-blue-700 text-xl' />Danışmanlık hizmeti</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Tüm Türkiye’ye servis imkanı</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />10 yılı aşkın tecrübe.</li>
                          <li className='flex flex-row  gap-2 mt-2'><CiCircleCheck className='text-blue-700 text-xl' />Sayısız fabrika ve güç tesisi referans.</li>                      
                        </ul>
                        <div className='welcom-to-section-bottom'></div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
     
    </div>
  );
}

export default AboutUsAdvance;
