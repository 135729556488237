import React from 'react'
import { VscProject } from "react-icons/vsc";
import { MdOutlineConstruction } from "react-icons/md";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TbSettingsUp } from "react-icons/tb";
import { motion } from 'framer-motion';

const Services = () => {
  return (
    <div className='services-section '>
      <motion.div className='card'
       initial="hidden"
       whileInView="visible"
       viewport={{once:true, amount:0.5}}
       transition={{duration:1}}
       variants={{
         hidden:{opacity:0, x:100},
         visible:{opacity:1 , x:0}
       }}   
      >
        <div className='icon'>
        <VscProject />
        </div>
        <h2>Proje Danışmanlık</h2>
        <p>Proje danışmanlığı yapan bir şirket, projelerin planlanması, yönetimi ve uygulanması süreçlerinde uzman desteği sağlayarak, projelerin zamanında ve bütçesinde başarıya ulaşmalarını sağlar, riskleri minimize eder.</p>
        <a href='/' className='button'>Daha Fazla</a>
      </motion.div>

      <motion.div className='card'
      initial="hidden"
      whileInView="visible"
      viewport={{once:true, amount:0.5}}
      transition={{duration:1}}
      variants={{
        hidden:{opacity:0, x:-100},
        visible:{opacity:1 , x:0}
      }}   
      >
        <div className='icon'>
        <MdOutlineConstruction />
        </div>
        <h2>Inşaat</h2>
        <p>Şirketimiz, inşaat danışmanlığı konusunda uzmanlaşmıştır ve inşaat projelerinin başarılı bir şekilde tamamlanmasını sağlamak için planlama, yönetim ve uygulama süreçlerinde  rehberlik sunmaktadır.</p>
        <a href='/' className='button'>Daha Fazla</a>
      </motion.div>

      <motion.div className='card'
      initial="hidden"
      whileInView="visible"
      viewport={{once:true, amount:0.5}}
      transition={{duration:1}}
      variants={{
        hidden:{opacity:0, x:100},
        visible:{opacity:1 , x:0}
      }}   
      >
        <div className='icon'>
        <LiaChalkboardTeacherSolid />
        </div>
        <h2>Fizibilete ve Etüd</h2>
        <p>Fizibilite ve etüt çalışmaları , projelerin uygulanabilirliğini titizlikle değerlendirir,  maliyet analizleri yapar, riskleri belirler ve yenilikçi çözüm önerileri sunarak projelerin başarılı bir başlangıç yapmasını sağlar. Kalitemizle fark yaratıyoruz.</p>
        <a href='/' className='button'>Daha Fazla</a>
      </motion.div>

      <motion.div className='card'
      initial="hidden"
      whileInView="visible"
      viewport={{once:true, amount:0.5}}
      transition={{duration:1}}
      variants={{
        hidden:{opacity:0, x:-100},
        visible:{opacity:1 , x:0}
      }}   
      >
        <div className='icon'>
        <TbSettingsUp />
        </div>
        <h2>Kurulum</h2>
        <p> Kurulum sürecinde, her adımda özenle ilerliyoruz. Yazılım kurulumunda özel ihtiyaçları göz önünde bulundurarak detaylı çözümler sunuyoruz. Müşteri memnuniyeti ve sorunsuz bir kurulum için özveriyle çalışıyoruz.</p>
        <a href='/' className='button'>Daha Fazla</a>
      </motion.div>
    </div>
  )
}

export default Services
